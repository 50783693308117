import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '..'
import { API_URL } from '../utils/consts'
import header from '../assets/header.png'
import footerdost4 from '../assets/footerdost.png'
import Carousel from 'react-multi-carousel'

const About = observer(() => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };


    const { content } = useContext(Context)
    const navigate = useNavigate()
    useEffect(() => {
        content.setCurrentTab(3)
    })
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <div className='home d-flex flex-column justify-content-around' style={{ background: `url(${API_URL + content.mainBanner})`, height: '50vh', position: 'relative' }}>
                <div className='container d-flex flex-column justify-content-center align-items-center' style={{ height: '50vh', zIndex: 10 }}>
                    <div className='container d-flex flex-column justify-content-center align-items-center' style={{ borderRadius: '30px', padding: '40px 40px', width: '70%', background: 'rgb(255 255 255 / 33%)' }}>
                        <div className='container d-flex flex-column justify-content-center align-items-center' style={{ borderRadius: '10px', fontSize: '4rem', width: '100%', background: '#6c6c6c' }}>
                            <h1 style={{ marginBottom: 0 }} >О НАС</h1>
                        </div>
                    </div>

                </div>
                <div className='img' style={{ background: `url(${header})` }} ><img style={{ visibility: 'hidden', width: '100%' }} src={header} /></div>
            </div>


            <div className='home-dost container pb-3 mb-5' style={{ zIndex: 10 }}>
                <div className=' d-lg-flex flex-row justify-content-between align-items-center d-none'>

                    {
                        content.dostizheniya.length === 0 ? '' :

                            content.dostizheniya.map(el => {

                                return (
                                    <div key={el.id} className=' d-flex flex-column justify-content-between align-items-center '>


                                        <img src={API_URL + el.attributes.image.data[0].attributes.url} />
                                        <div className='home-dost-text d-flex flex-row justify-content-between align-items-center'>
                                            <div className='home-dost-text-count'>{el.attributes.count}</div>
                                            <div>{el.attributes.name}</div>
                                        </div>
                                    </div>
                                )
                            })
                    }

                </div>

            </div>

            <div className='container' style={{ whiteSpace: 'pre-wrap', }}>

                <img width='100%' src={typeof content.about.attributes === 'undefined' ? '' : API_URL + content.about.attributes.image.data.attributes.url} />
                <div className='row pt-5' style={{ whiteSpace: 'pre-wrap', textAlign: 'center', color: '#504b4b' }}><h1>КОРОТКО О НАС</h1></div>
                <div className='row pt-5' style={{ whiteSpace: 'pre-wrap', fontSize: '1.5rem', color: '#504b4b' }}>  {typeof content.about.attributes === 'undefined' ? '' : content.about.attributes.AboutUsHome} </div>

                <div className='row pt-5' style={{ whiteSpace: 'pre-wrap', textAlign: 'center', color: '#504b4b' }}><h1>ДОКУМЕНТЫ</h1></div>
                <div className='row pt-5' style={{ whiteSpace: 'pre-wrap', textAlign: 'center', color: '#504b4b' }}>
                    {typeof content.about.attributes === 'undefined' ? '' :
                        content.about.attributes.docs.data.map(el => {
                            return (<div className='col-lg-3'><a target="_blank" href={API_URL + el.attributes.url}><img src={API_URL + el.attributes.formats.medium.url} width="100%" /></a></div>)
                        })}
                </div >
                <div className='row pt-5' style={{ whiteSpace: 'pre-wrap', textAlign: 'center', color: '#504b4b' }}><h1>ПЕРСОНАЛ</h1></div>
                <div className='row pt-5 pb-5' style={{ whiteSpace: 'pre-wrap', textAlign: 'center', color: '#504b4b' }}>
                    <div className='col-lg-4'>

                        {typeof content.about.attributes === 'undefined' ? '' :
                            (<img src={API_URL + content.about.attributes.personalImage.data.attributes.url} width="100%" />)
                        }

                    </div>
                    <div className='col-lg-8 text-left' style={{ whiteSpace: 'pre-wrap', textAlign: 'left', fontSize: '1.5rem', color: '#504b4b' }}>

                        {typeof content.about.attributes === 'undefined' ? '' :
                            content.about.attributes.personalText}
                    </div>
                </div>
            </div>




            <div className=' ' style={{ background: `url(${footerdost4})`, width: 'fit-content', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', position: 'absolute', right: 0 }}><img src={footerdost4} style={{ width: '100%', visibility: 'hidden' }} /></div>

            <div className='slider' style={{ marginBottom: '30px' }}>
                <h1 className='pb-4'>НАМ ДОВЕРЯЮТ</h1>
                {content.logotypes.length > 0 ? <Carousel swipeable={false}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    ssr={false} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={2000}
                    keyBoardControl={true}

                    transitionDuration={5000}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}

                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px">
                    {content.logotypes.length > 0 ? content.logotypes.map(el => {
                        return (<div >
                            <img src={API_URL + el.attributes.formats.thumbnail.url} height="70px" />
                        </div>)
                    }) : ''

                    }

                </Carousel> : ''}
            </div>
        </>

    )
}
)

export default About