import axios from 'axios';
import React, { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { API_URL } from '../utils/consts';

const ContactForm = () => {
    const ref = useRef(null);
    const ref2 = useRef(null);

    const [show, setShow] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [theme, setTheme] = useState('');
    const [text, setText] = useState('');
    const [showG, setShowg] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSend = (e) => {
        const valid = ref.current.checkValidity();
        ref.current.reportValidity();

        e.preventDefault();
        if(valid){
            ref2.current.disabled = true;
        axios.post(API_URL + '/api/obrashheniyas',
            {
                "data": {
                    "name" : name,
                    "email": email,
                    "text" : text,
                    "theme": theme,
                }
            }).then(data => {
  
                setShowg(true)
                setTimeout(function(){      
                    setShowg(false)
                }, 2700);  
                ref2.current.disabled = false;
            })
        }
    }
    return (
        <>
 
            <a class="my-button cursor-pointer" onClick={handleShow} style={{ color: 'white', height: 'fit-content' }}>СВЯЗАТЬСЯ</a>


            <Modal show={show} onHide={handleClose} style={{ color: '#504b4b' }}>
                <Modal.Header closeButton>
                    <Modal.Title>СВЯЗАТЬСЯ С НАМИ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>


                        <div className={`container ${!showG ? 'd-none' : ''}`} >
                            <div className='d-flex flex-row justify-content-around align-items-center'>
                                <div  style={{ width: '30px', height: '70px' }} >
                                    <svg viewbox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" className={`${showG ? 'animate' : ''}`}>
                                        <path d="M 18 32.34 l -8.34 -8.34 -2.83 2.83 11.17 11.17 24 -24 -2.83 -2.83 z" stroke="#3da35a" fill="transparent" />
                                    </svg>
                                </div>
                                <h4>Успешно отправлено!</h4>
                            </div>

                        </div>

                    </div>
                    <form ref={ref}>
                        <div className={`container ${showG ? 'd-none' : ''}`}>
                            <div className='row'>
                                <div className='col-lg-6 mt-3'>
                                    <input type="text" class="form-control" placeholder="Ваше имя" value={name} onChange={(e) => setName(e.target.value)} variant='dark' aria-label="Username" aria-describedby="basic-addon1" required />
                                </div>
                                <div className='col-lg-6 mt-3'>
                                    <input type="email" class="form-control" placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} aria-label="Username" aria-describedby="basic-addon1" required />
                                </div>
                                <div className='col-lg-12 mt-3'>
                                    <input type="text" class="form-control" placeholder="Тема" variant='dark' value={theme} onChange={(e) => setTheme(e.target.value)} aria-label="Username" aria-describedby="basic-addon1" required />
                                </div>
                                <div className='col-lg-12 mt-3'>
                                    <textarea type="text" rows="5" class="form-control" placeholder="Текст обращения" value={text} onChange={(e) => setText(e.target.value)} variant='dark' aria-label="Username" aria-describedby="basic-addon1" required />
                                </div>

                                <div className='col-lg-12 mt-3 d-flex justify-content-end'>
                                    <Button variant="secondary" onClick={handleClose} style={{ marginRight: '10px' }}>
                                        Отмена
                                    </Button>
                                    <Button variant="" className='btn-msuccess' type="submit" onClick={handleSend} ref={ref2}>
                                        Отправить
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>

            </Modal>
        </>
    );
}

export default ContactForm