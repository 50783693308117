import About from "./pages/About";
import Contacts from "./pages/Contacts";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import PortfolioDetail from "./pages/PortfolioDetail";
import Services from "./pages/Services";
import ServicesDetail from "./pages/ServicesDetail";
import Vacancy from "./pages/Vacancy";
import { ABOUT_CATEGORY_ROUTE, CONTACTS_ROUTE, HOME_ROUTE, PORTFOLIO_CATEGORY_ROUTE, PORTFOLIO_DETAIL_ROUTE, SERVICE_CATEGORY_ROUTE, SERVICE_DETAIL_ROUTE, VACANCY_ROUTE } from "./utils/consts";

export const publicRoutes = [
    {
        path: HOME_ROUTE,
        Component: <Home />
    },
    {
        path: PORTFOLIO_CATEGORY_ROUTE,
        Component: <Portfolio />
    },
    {
        path: SERVICE_CATEGORY_ROUTE,
        Component: <Services />
    },
    {
        path: ABOUT_CATEGORY_ROUTE,
        Component: <About />
    },
    {
        path: CONTACTS_ROUTE,
        Component: <Contacts />
    },
    {
        path: SERVICE_DETAIL_ROUTE,
        Component: <ServicesDetail />
    },
    {
        path: PORTFOLIO_DETAIL_ROUTE,
        Component: <PortfolioDetail />
    },
    {
        path: VACANCY_ROUTE,
        Component: <Vacancy />
    }
]