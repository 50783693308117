import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '..'
import { API_URL, PORTFOLIO_CATEGORY_ROUTE } from '../utils/consts'
import header from '../assets/header.png'

const Portfolio = observer(() => {
    const { content } = useContext(Context)
    const navigate = useNavigate()
    useEffect(() => {
        content.setCurrentTab(1)
    })

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])


    return (
        <>
            <div className='home d-flex flex-column justify-content-around' style={{ background: `url(${API_URL + content.mainBanner})`, height: '50vh', position: 'relative' }}>
                <div className='container d-flex flex-column justify-content-center align-items-center' style={{ height: '50vh', zIndex: 10 }}>
                    <div className='container d-flex flex-column justify-content-center align-items-center' style={{ borderRadius: '30px', padding: '40px 40px', width: '70%', background: 'rgb(255 255 255 / 33%)' }}>
                        <div className='container d-flex flex-column justify-content-center align-items-center' style={{ borderRadius: '10px', fontSize: '4rem', width: '100%', background: '#6c6c6c' }}>
                            <h1 style={{ marginBottom: 0 }} >НАШИ ПРОЕКТЫ</h1>
                        </div>
                    </div>

                </div>
                <div className='img' style={{ background: `url(${header})` }} ><img style={{ visibility: 'hidden', width: '100%' }} src={header} /></div>
            </div>

            <div className='container pt-5' style={{color: 'rgb(80, 75, 75)', fontSize: '1.4rem'}} >
            Амурский ГПЗ, ЗапСибНефтехим, СКА Арена, ГЭС-2, отель класса 5 звёзд в Сочи, новый аэропорт, Омскй НПЗ. За годы работы мы имели дело с самыми разными проектами и можем сказать со всей ответственностью, что нет такой задачи, с которой мы бы не сумели справиться. Посмотрите кейсы в портфолио - наши работы говорят сами за себя.
                {
                    (content.portfolio.length === 0 || true )? '' :
                        content.portfolio.map((el,i ) => {
                            if (i+1 === content.portfolio.length ){
                                return (el.attributes.name)
                            } else{
                                return (el.attributes.name + ', ')
                            }
                        })
                }
            </div>

            <div className='container-fluid'>
                <div className='row mt-5'>
                    {
                        content.portfolio.length === 0 ? '' :

                            content.portfolio.map((el, i) => {
                                return (
                                    <div onClick={() => { navigate(PORTFOLIO_CATEGORY_ROUTE + el.id) }} className={`cursor-pointer col-md-4 col-12 pb-3 `}>
                                        <div key={el.id} className='home-portfolio-card d-flex flex-column justify-content-end align-items-center  p-0'
                                            style={{ background: `url(${API_URL + el.attributes.images.data[0].attributes.formats.small.url})` }}>



                                            <div className='home-portfolio-text d-flex flex-column justify-content-start align-items-start w-100 p-3' style={{ color: '#504b4b', fontSize: '1.5em', minHeight: '140px',fontWeight: '800' }}>

                                                <div style={{textShadow: '1px 2px 2px rgb(114 114 114)'}}>{el.attributes.city}</div>
                                                <div style={{textShadow: '1px 2px 2px rgb(114 114 114)'}}>{el.attributes.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                    }
                </div>

                <div className='w-100 d-flex justify-content-end cursor-pointer' onClick={() => { navigate(PORTFOLIO_CATEGORY_ROUTE) }}>Смотреть все проекты</div>
            </div>
        </>

    )
}
)

export default Portfolio