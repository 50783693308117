import { BrowserRouter } from "react-router-dom";
import AppRouter from "./components/AppRouter";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import './App.css'
import { Context } from ".";
import { useContext, useEffect } from "react";
import axios from "axios";
import { API_URL } from "./utils/consts";
import { observer } from "mobx-react-lite";

const App = observer(  () => {

  const {content} = useContext(Context)

  useEffect(() => {
    axios.get(API_URL + '/api/kontakty').then(data => {
      content.setKontakty(data.data.data.attributes)
    })

    axios.get(API_URL + '/api/referens?populate=*').then(data => {
      
      content.setReferens(data.data.data.attributes.file.data.attributes.url)
    })

      axios.get(API_URL + '/api/kartinka-glavnyj-banner?populate=*').then(data => {
          content.setMainBanner(data.data.data.attributes.image.data.attributes.url)

      })
      axios.get(API_URL + '/api/tekst-v-shapke').then(data => {
          content.setHeaderText(data.data.data.attributes.HeaderText)

      })

      axios.get(API_URL + '/api/kategorii-uslugs?populate=*').then(data => {
          content.setKategorii(data.data.data)

      })

      axios.get(API_URL + '/api/dostizheniyas?populate=*').then(data => {
          content.setDostizheniya(data.data.data)

      })

      axios.get(API_URL + '/api/o-kompanii?populate=*').then(data => {
          content.setAbout(data.data.data)

      })

      axios.get(API_URL + '/api/portfolios?populate=*').then(data => {
          content.setPortfolio(data.data.data)

      })


      axios.get(API_URL + '/api/portfolios?populate=*').then(data => {
          content.setPortfolio(data.data.data)

      })

      axios.get(API_URL + '/api/logo-klientov?populate=*').then(data => {
          content.setLogotypes(data.data.data.attributes.images.data)
      })

  }, [])



  return (
    <BrowserRouter>
      <NavBar/>
        <AppRouter />
      <Footer/>
  </BrowserRouter>
  );

})

export default App;