export const HOME_ROUTE = '/'
export const SERVICE_CATEGORY_ROUTE = '/services/'
export const SERVICE_DETAIL_ROUTE = '/services/:slug'
export const PORTFOLIO_CATEGORY_ROUTE = '/portfolio/'
export const PORTFOLIO_DETAIL_ROUTE = '/portfolio/:slug'

export const VACANCIES_CATEGORY_ROUTE = '/vacancies/'

export const ABOUT_CATEGORY_ROUTE = '/about/'
export const CONTACTS_ROUTE = '/contacts/'

export const API_URL = 'https://gizma72.ru'
export const VACANCY_ROUTE = '/vacancy/'