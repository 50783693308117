import axios from 'axios'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { Context } from '..'
import { API_URL, PORTFOLIO_CATEGORY_ROUTE, SERVICE_CATEGORY_ROUTE } from '../utils/consts'
import header from '../assets/header.png'
import dost1 from '../assets/dost1.png'
import dost2 from '../assets/dost2.png'
import dost3 from '../assets/dost3.png'
import dost4 from '../assets/dost4.png'
import footerdost4 from '../assets/footerdost.png'
import "react-multi-carousel/lib/styles.css";

import { useNavigate } from 'react-router-dom'

import Carousel from 'react-multi-carousel'
import ContactForm from '../components/ContactForm'

const Home = observer(() => {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    const { content } = useContext(Context)
    const navigate = useNavigate()
    useEffect(() => {
        content.setCurrentTab(0)
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className='home d-flex flex-column justify-content-around' style={{ background: `url(${API_URL + content.mainBanner})`, position: 'relative' }}>
                <div className='container d-flex flex-column justify-content-around' style={{ height: '50vh', zIndex: 10 }}>

                    <h1 style={{ fontSize: '7rem' }}>ГИZМА</h1>
                    <h4 style={{ whiteSpace: 'pre-wrap', fontSize: '2.5rem' }}>{content.headerText}</h4>
                    <ContactForm />
                </div>
                <div className='img' style={{ background: `url(${header})` }} ><img style={{ visibility: 'hidden', width: '100%' }} src={header} /></div>
            </div>

            <div className='home-services' style={{ zIndex: 10 }}>
                <div className='container'>
                    <div className='row'>
                        {
                            content.kategorii.length === 0 ? '' :

                                content.kategorii.map(el => {

                                    return (
                                        <div key={el.id} onClick={() => { content.setCurrentTab(2); navigate(SERVICE_CATEGORY_ROUTE + el.id) }} className='col-6 col-lg-2 d-flex flex-column justify-content-between align-items-center cursor-pointer'>
                                            <div style={{ zIndex: 9, fontSize: "20px" }} >
                                                {el.attributes.name}
                                            </div>
                                            <div>
                                                <img src={API_URL + el.attributes.image.data.attributes.url} />
                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
            </div>



            <div className='home-dost container' style={{ zIndex: 10 }}>
                <div className=' d-lg-flex flex-row justify-content-between align-items-center d-none'>

                    {
                        content.dostizheniya.length === 0 ? '' :

                            content.dostizheniya.map(el => {

                                return (
                                    <div key={el.id} className=' d-flex flex-column justify-content-between align-items-center '>


                                        <img src={API_URL + el.attributes.image.data[0].attributes.url} />
                                        <div className='home-dost-text d-flex flex-row justify-content-between align-items-center'>
                                            <div className='home-dost-text-count'>{el.attributes.count}</div>
                                            <div>{el.attributes.name}</div>
                                        </div>
                                    </div>
                                )
                            })
                    }

                </div>
                <div className='row p-3 pt-5' style={{ whiteSpace: 'pre-wrap', fontSize: '1.5rem', color: '#504b4b' }}>  {typeof content.about.attributes === 'undefined' ? '' : content.about.attributes.AboutUs} </div>
            </div>




            <div className='home-preim container-fluid' style={{ zIndex: 10 }}>
                <h1>НАШИ ПРИЕМУЩЕСТВА</h1>
                <div className='w-100 d-none d-lg-flex' style={{ background: `url(${dost1})`, backgroundSize: 'contain' }}><img src={dost1} style={{ width: '100%', visibility: 'hidden' }} /></div>

                <div className='w-100 d-flex d-lg-none' style={{ background: `url(${dost2})`, backgroundSize: 'contain' }}><img src={dost2} style={{ width: '100%', visibility: 'hidden' }} /></div>


            </div>
            <div className=' ' style={{ background: `url(${dost3})`, width: 'fit-content', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }}><img src={dost3} style={{ width: '100%', visibility: 'hidden' }} /></div>


            <div className='home-portfolio'>
                <h1>ПОРТФОЛИО</h1>

                <div className='container-fluid pt-5'>
                    <div className='row mt-5 '>
                        {
                            content.portfolio.length === 0 ? '' :

                                content.portfolio.map((el, i) => {
                                    return (
                                        <div onClick={() => { navigate(PORTFOLIO_CATEGORY_ROUTE + el.id) }} className={`cursor-pointer col-md-4 col-12 pb-3 ${i < 3 ? '' : 'd-none'}`}>
                                            <div key={el.id} className='home-portfolio-card d-flex flex-column justify-content-end align-items-center  p-0'
                                                style={{ background: `url(${API_URL + el.attributes.images.data[0].attributes.formats.small.url})` }}>



                                                <div className='home-portfolio-text d-flex flex-column justify-content-end align-items-start w-100 p-3'>

                                                    <div style={{ textShadow: '1px 2px 2px rgb(114 114 114)' }}>{el.attributes.city}</div>
                                                    <div style={{ textShadow: '1px 2px 2px rgb(114 114 114)', textAlign: 'left' }}>{el.attributes.name}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </div>
                    <div className='w-100 d-flex justify-content-end cursor-pointer'>
                        <a className='my-button cursor-pointer' onClick={() => { navigate(PORTFOLIO_CATEGORY_ROUTE) }}>Смотреть все проекты</a>
                    </div>
                </div>
            </div>

            <div className=' ' style={{ background: `url(${dost4})`, width: 'fit-content', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', position: 'absolute', right: 0 }}><img src={dost4} style={{ width: '100%', visibility: 'hidden' }} /></div>





            <div className='home-services-bottom' style={{ zIndex: 10, position: 'relative' }}>
                <h1>НАШИ УСЛУГИ</h1>



                <div className='container-fluid ' style={{ paddingBottom: '15px', lineHeight: '30px' }}>
                    <div className='row ' style={{ marginTop: '15px' }}>
                        {
                            content.kategorii.length === 0 ? '' :

                                content.kategorii.map((el, i) => {

                                    return (


                                        <div className='col-lg-4 text-left pr-3 pl-3' style={{ zIndex: 10, marginBottom: '15px', position: 'relative', }}>
                                            <div key={el.id} onClick={() => { navigate(SERVICE_CATEGORY_ROUTE + el.id) }} style={{ background: '#f5f5f5', zIndex: 10 }} className='home-services-bottom-card  cursor-pointer'>
                                                <div style={{ whiteSpace: 'pre-wrap', padding: '2rem', textAlign: 'left' }}>
                                                    {el.attributes.homeText}
                                                </div>
                                                <div>

                                                </div>
                                                <div className='home-services-bottom-name d-flex justify-content-center align-items-center'>
                                                    <div style={{ fontSize: '24px', lineHeight: '20px' }}>
                                                        {el.attributes.name}
                                                    </div>
                                                </div>
                                            </div>
                                            {i === 0 && window.innerWidth > 993 ?
                                                <img className="home-services-bottom-img" src={API_URL + el.attributes.image.data.attributes.url} style={{ position: 'absolute', width: '150px', left: '-13px', bottom: '90px', filter: ' brightness(0) invert(0.5)' }} />
                                                : <img className="home-services-bottom-img" src={API_URL + el.attributes.image.data.attributes.url} style={{ position: 'absolute', width: '150px', right: '-13px', bottom: '90px', filter: ' brightness(0) invert(0.5)' }} />
                                            }
                                        </div>




                                    )
                                })
                        }







                    </div>

                </div>

                {
                    content.kategorii.length === 0 ? '' :

                        content.kategorii.map((el, i) => {
                            if (i % 3 === 2)
                                return (


                                    <div className='d-none d-md-absolute' style={{ background: '#6ccf5b', zIndex: 0, position: 'absolute', top: `${(555 + (615 * (((i + 1) / 3) - 1)))}px`, width: '100%', height: '70px' }} ></div>




                                )
                        })
                }

            </div>






            <div className=' ' style={{ background: `url(${footerdost4})`, width: 'fit-content', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', position: 'absolute', right: 0 }}><img src={footerdost4} style={{ width: '100%', visibility: 'hidden' }} /></div>

            <div className='slider' style={{ marginBottom: '30px', }}>
                <h1 className='pb-4'>НАМ ДОВЕРЯЮТ</h1>
                {content.logotypes.length > 0 ? <Carousel swipeable={false}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    ssr={false} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={2000}
                    keyBoardControl={true}

                    transitionDuration={5000}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}

                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px">
                    {content.logotypes.length > 0 ? content.logotypes.map(el => {
                        return (<div >
                            <img src={API_URL + el.attributes.formats.thumbnail.url} height="70px" />
                        </div>)
                    }) : ''

                    }

                </Carousel> : ''}
            </div>
        </>

    )
}
)

export default Home