import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "..";
import logo from '../assets/logo.png'
import phone from '../assets/phone.png'
import vk from '../assets/vk.png'
import whatsapp from '../assets/whatsapp.png'
import inst from '../assets/inst.png'
import { ABOUT_CATEGORY_ROUTE, CONTACTS_ROUTE, HOME_ROUTE, PORTFOLIO_CATEGORY_ROUTE, SERVICE_CATEGORY_ROUTE, VACANCY_ROUTE } from "../utils/consts";
import Dropdown from 'react-bootstrap/Dropdown';

const NavBar = observer(() => {
    const navigate = useNavigate()
    const { content } = useContext(Context)
    return (
        <><div className="header ">
            <div className="container  d-none d-lg-grid w-100">

                <div className="d-flex flex-row justify-content-between">
                    <div style={{zIndex: 10}}>
                        <a onClick={() => navigate(HOME_ROUTE)} className="cursor-pointer" style={{zIndex: 10}}><img src={logo} width="120px" /></a>
                        
                        <a className={`g-nav-link  ${content.currentTab === 1 ? 'active' : ''}`} onClick={() => navigate(PORTFOLIO_CATEGORY_ROUTE)} >Портфолио</a>
                        <a className={`g-nav-link  ${content.currentTab === 2 ? 'active' : ''}`} onClick={() => navigate(SERVICE_CATEGORY_ROUTE)} >Услуги</a>
                        <a className={`g-nav-link  ${content.currentTab === 3 ? 'active' : ''}`} onClick={() => navigate(ABOUT_CATEGORY_ROUTE)}>О нас</a>
                        <a className={`g-nav-link  ${content.currentTab === 4 ? 'active' : ''}`} onClick={() => navigate(VACANCY_ROUTE)}>Вакансии</a>
                        <a className={`g-nav-link  ${content.currentTab === 5 ? 'active' : ''}`} onClick={() => navigate(CONTACTS_ROUTE)}>Контакты</a>
                    </div>
                    <div className="d-flex flex-row justify-content-end align-items-center phone-section">
                        <a href={`tel:${content.kontakty.phone}`}  ><img src={phone} width="40px" style={{ marginRight: '10px' }} />{content.kontakty.phone}</a>
                        <a target="_blank" href={content.kontakty.vk} ><img src={vk} width="40px" /></a>
                        <a target="_blank" href={content.kontakty.whatsapp} ><img src={whatsapp} width="40px" /></a>
                        <a target="_blank" href={content.kontakty.inst} ><img src={inst} width="40px" /></a>
                    </div>

                </div>

            </div>


            <div className=" d-flex d-lg-none w-100 header-m" style={{height: '80px'}}>

                <div className="d-flex flex-row w-100 justify-content-between">
                    <div className="d-none1">
                        <a onClick={() => navigate(HOME_ROUTE)} className="cursor-pointer" style={{zIndex: 10}}><img style={{zIndex: 9999, position: 'absolute'}}  src={logo} width="120px" /></a>
                        <a className={`d-none g-nav-link  ${content.currentTab === 0 ? 'active' : ''}`} onClick={() => navigate(HOME_ROUTE)}>Главная</a>
                        <a className={`d-none g-nav-link  ${content.currentTab === 1 ? 'active' : ''}`} onClick={() => navigate(PORTFOLIO_CATEGORY_ROUTE)} >Портфолио</a>
                        <a className={`d-none g-nav-link  ${content.currentTab === 2 ? 'active' : ''}`} onClick={() => navigate(SERVICE_CATEGORY_ROUTE)} >Услуги</a>
                        <a className={`d-none g-nav-link  ${content.currentTab === 3 ? 'active' : ''}`} onClick={() => navigate(ABOUT_CATEGORY_ROUTE)}>О нас</a>
                        <a className={`d-none g-nav-link  ${content.currentTab === 4 ? 'active' : ''}`} onClick={() => navigate(ABOUT_CATEGORY_ROUTE)}>Вакансии</a>
                        <a className={`d-none g-nav-link  ${content.currentTab === 5 ? 'active' : ''}`} onClick={() => navigate(CONTACTS_ROUTE)}>Контакты</a>
                    </div>
                    <div className="d-flex flex-row justify-content-end align-items-center phone-section">
                        <a className="d-none" href={`tel:${content.kontakty.phone}`}  ><img src={phone} width="40px" style={{ marginRight: '10px' }} />{content.kontakty.phone}</a>
                        <a target="_blank" href={content.kontakty.vk} ><img src={vk} width="40px" /></a>
                        <a target="_blank" href={content.kontakty.whatsapp} ><img src={whatsapp} width="40px" /></a>
                        <a target="_blank" href={content.kontakty.inst} ><img src={inst} width="40px" /></a>




                        <Dropdown>
                            <Dropdown.Toggle variant="success1" id="dropdown-basic" style={{ width: '60px' }}>
                                <svg id="Icons" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><defs></defs>
                                <path class="cls-1" fill="#6ccf5b" d="M21,1H3A3,3,0,0,0,3,7H21a3,3,0,0,0,0-6Zm0,4H3A1,1,0,0,1,3,3H21a1,1,0,0,1,0,2Z" />
                                <path class="cls-1" fill="#6ccf5b" d="M21,17H3a3,3,0,0,0,0,6H21a3,3,0,0,0,0-6Zm0,4H3a1,1,0,0,1,0-2H21a1,1,0,0,1,0,2Z" />
                                <path class="cls-1" fill="#6ccf5b" d="M21,9H3a3,3,0,0,0,0,6H21a3,3,0,0,0,0-6Zm0,4H3a1,1,0,0,1,0-2H21a1,1,0,0,1,0,2Z" /></svg>

                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => navigate(PORTFOLIO_CATEGORY_ROUTE)}>Портфолио</Dropdown.Item>
                                <Dropdown.Item onClick={() => navigate(SERVICE_CATEGORY_ROUTE)}>Услуги</Dropdown.Item>
                                <Dropdown.Item onClick={() => navigate(ABOUT_CATEGORY_ROUTE)}>О нас</Dropdown.Item>
                                <Dropdown.Item onClick={() => navigate(VACANCY_ROUTE)}>Вакансии</Dropdown.Item>
                                <Dropdown.Item onClick={() => navigate(CONTACTS_ROUTE)}>Контакты</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                </div>


            </div>
        </div>
        </>
    )
})

export default NavBar