import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import ContentStore from './stores/ContentStore';

export const Context = createContext(null)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Context.Provider value={{
    content: new ContentStore()
  }} >
    <App />

</Context.Provider>
);

