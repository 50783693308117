import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '..'
import { API_URL } from '../utils/consts'
import header from '../assets/header.png'
import ContactForm from '../components/ContactForm'
import axios from 'axios'
import Button from 'react-bootstrap/Button';

const Contacts = observer(() => {
    const ref = useRef(null);
    const ref2 = useRef(null);

    const [show, setShow] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [theme, setTheme] = useState('');
    const [text, setText] = useState('');
    const [showG, setShowg] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSend = (e) => {
        const valid = ref.current.checkValidity();
        ref.current.reportValidity();

        e.preventDefault();
        if (valid) {
            ref2.current.disabled = true;
            axios.post(API_URL + '/api/obrashheniyas',
                {
                    "data": {
                        "name": name,
                        "email": email,
                        "text": text,
                        "theme": theme,
                    }
                }).then(data => {

                    setShowg(true)
                    setTimeout(function () {
                        setShowg(false)
                    }, 2700);
                    ref2.current.disabled = false;
                })
        }
    }


    const { content } = useContext(Context)
    const navigate = useNavigate()
    useEffect(() => {
        content.setCurrentTab(5)
    })
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    let emails = []
    if(typeof content.kontakty.email !== 'undefined')
         emails = content.kontakty.email.split(' ')

    return (
        <>
            <div className='home d-flex flex-column justify-content-around' style={{ background: `url(${API_URL + content.mainBanner})`, height: '50vh', position: 'relative' }}>
                <div className='container d-flex flex-column justify-content-center align-items-center' style={{ height: '50vh', zIndex: 10 }}>
                    <div className='container d-flex flex-column justify-content-center align-items-center' style={{ borderRadius: '30px', padding: '40px 40px', width: '70%', background: 'rgb(255 255 255 / 33%)' }}>
                        <div className='container d-flex flex-column justify-content-center align-items-center' style={{ borderRadius: '10px', fontSize: '4rem', width: '100%', background: '#6c6c6c' }}>
                            <h1 style={{ marginBottom: 0 }} >КОНТАКТЫ</h1>
                        </div>
                    </div>

                </div>
                <div className='img' style={{ background: `url(${header})` }} ><img style={{ visibility: 'hidden', width: '100%' }} src={header} /></div>
            </div>
            <div className='container pt-5' style={{ whiteSpace: 'pre-wrap', textAlign: 'left', fontSize: '1.5rem', color: '#504b4b' }}>
                <div style={{ position: 'relative', overflow: 'hidden' }}><a href="https://yandex.ru/maps/11175/tobolsk/?feedback=map%2Fedit&feedback-context=map.controls&ll=68.281241%2C58.211101&utm_medium=mapframe&utm_source=maps&z=17.06
            " style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: '14px' }}>2-я Северная улица, 47 — Яндекс Карты</a><iframe src="https://yandex.ru/map-widget/v1/-/CCUjQOF3gA" width="100%" height="400" frameborder="1" allowfullscreen="true" style={{ position: 'relative' }}></iframe></div>

                <div className='container mb-5 mt-5'>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <h1>КОНТАКТЫ</h1>
                            <h4>{content.kontakty.address}</h4>
                            <br />
                            <h4>ТЕЛЕФОН:   <a style={{ textDecoration: 'none', color: '#6ccf5b' }} href={`tel:${content.kontakty.phone}`}>{content.kontakty.phone}</a></h4>
                            <div className='d-flex flex-row'>
                                <h4 className=''>ЭЛ. ПОЧТА: </h4>
                                  <div> 
                                    {emails.map(el => {
                                        return (
                                           <><h4> <a style={{ textDecoration: 'none', color: '#6ccf5b' }} href={`mailto:${el}`}>{el}</a></h4></>
                                        )
                                    })}
                                     
                                </div>
                                
                            </div>
                            <br />
                        </div>
                        <div className='col-lg-9 '>
                            <div>


                                <div className={`container mb-5 ${!showG ? 'd-none' : ''}`} >
                                    <div className='d-flex flex-row justify-content-around align-items-center'>
                                        <div style={{ width: '30px', height: '70px' }} >
                                            <svg viewbox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" className={`${showG ? 'animate' : ''}`}>
                                                <path d="M 18 32.34 l -8.34 -8.34 -2.83 2.83 11.17 11.17 24 -24 -2.83 -2.83 z" stroke="#3da35a" fill="transparent" />
                                            </svg>
                                        </div>
                                        <h4>Успешно отправлено!</h4>
                                    </div>

                                </div>

                            </div>
                            <form ref={ref}>
                                <div className={`container ${showG ? 'd-none' : ''}`}>
                                    <div className='row'>
                                        <div className='col-lg-6 mt-3'>
                                            <input type="text" class="form-control" placeholder="Ваше имя" value={name} onChange={(e) => setName(e.target.value)} variant='dark' aria-label="Username" aria-describedby="basic-addon1" required />
                                        </div>
                                        <div className='col-lg-6 mt-3'>
                                            <input type="email" class="form-control" placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} aria-label="Username" aria-describedby="basic-addon1" required />
                                        </div>
                                        <div className='col-lg-12 mt-3'>
                                            <input type="text" class="form-control" placeholder="Тема" variant='dark' value={theme} onChange={(e) => setTheme(e.target.value)} aria-label="Username" aria-describedby="basic-addon1" required />
                                        </div>
                                        <div className='col-lg-12 mt-3'>
                                            <textarea type="text" rows="5" class="form-control" placeholder="Текст обращения" value={text} onChange={(e) => setText(e.target.value)} variant='dark' aria-label="Username" aria-describedby="basic-addon1" required />
                                        </div>

                                        <div className='col-lg-12 mt-3 d-flex justify-content-end'>
                                            <Button variant="secondary" onClick={handleClose} style={{ marginRight: '10px' }}>
                                                Отмена
                                            </Button>
                                            <Button className='btn-msuccess' type="submit" onClick={handleSend} ref={ref2}>
                                                Отправить
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>
        </>

    )
}
)

export default Contacts