import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Context } from '..'
import { API_URL, PORTFOLIO_CATEGORY_ROUTE } from '../utils/consts'
import header from '../assets/header.png'
import axios from 'axios'
import Carousel from 'react-multi-carousel'
import "react-multi-carousel/lib/styles.css";
import footerdost4 from '../assets/footerdost.png'


const ServicesDetail = observer(() => {
    const params = useParams()
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };


    const responsive2 = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
        };

    const { content } = useContext(Context)
    const navigate = useNavigate()
    useEffect(() => {
        content.setCurrentTab(2)
    })
    const [text, setText] = useState('')
    useEffect(() => {
        axios.get(API_URL + `/api/kategorii-uslugs/${params.slug}?populate=*`).then(data => {
            setText(data.data.data.attributes)
            console.log(data.data.data.attributes)
        })
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])


    return (
        <>
            <div className='home d-flex flex-column justify-content-around' style={{ background: `url(${API_URL + content.mainBanner})`, height: '50vh', position: 'relative' }}>
                <div className='container d-flex flex-column justify-content-center align-items-center' style={{ height: '50vh', zIndex: 10 }}>
                    <div className='container d-flex flex-column justify-content-center align-items-center' style={{ borderRadius: '30px', padding: '40px 40px', width: '70%', background: 'rgb(255 255 255 / 33%)' }}>
                        <div className='container d-flex flex-column justify-content-center align-items-center' style={{ borderRadius: '10px', fontSize: '4rem', width: '100%', background: '#6c6c6c' }}>
                            <h1 style={{ marginBottom: 0 }} >УСЛУГИ</h1>
                        </div>
                    </div>

                </div>
                <div className='img' style={{ background: `url(${header})` }} ><img style={{ visibility: 'hidden', width: '100%' }} src={header} /></div>
            </div>



            <div className='container' style={{ fontSize: '1.3rem', color: '#504b4b', marginTop: '100px' }}>

                <div className='row' style={{}}>


                    {
                        text === '' || text.images.data == null  ? '' : 
                        <Carousel 
                            centerMode={true}
                            swipeable={true}
                            draggable={true}
                            showDots={true}
                            responsive={responsive}
                            ssr={false} // means to render carousel on server-side.
                            infinite={true}
                            autoPlay={false}
                           // autoPlaySpeed={2000}
                            keyBoardControl={true}

                            //transitionDuration={5000}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile"]}

                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px">
                            {text !== '' ? text.images.data.map(el => {
                                return (<div className='w-100' style={{maxHeight: '70vh', paddingLeft:'10px', paddingRight:'10px'}}>
                                <img src={API_URL + el.attributes.url}  style={{width: '100%'}}/>
                            </div>)
                              
                            }) : ''

                            }

                        </Carousel>
                    }
                </div>
                <div className='row mt-5'>
                    {
                        text === '' ? '' :



                            <div>

                                <p>{text.name}</p>


                                <div style={{ whiteSpace: 'pre-wrap', fontSize: '1.2rem', paddingBottom: '40px' }}>{text.desc}</div>
                            </div>


                    }
                </div>


            </div>




            <div className=' ' style={{ background: `url(${footerdost4})`, width: 'fit-content', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', position: 'absolute', right: 0 }}><img src={footerdost4} style={{ width: '100%', visibility: 'hidden' }} /></div>

<div className='slider' style={{ marginBottom: '30px' }}>
    <h1 className='pb-4'>НАМ ДОВЕРЯЮТ</h1>
    {content.logotypes.length > 0 ? <Carousel swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive2}
        ssr={false} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={2000}
        keyBoardControl={true}

        transitionDuration={5000}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}

        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px">
        {content.logotypes.length > 0 ? content.logotypes.map(el => {
            return (<div >
                <img src={API_URL + el.attributes.formats.thumbnail.url} height="70px" />
            </div>)
        }) : ''

        }

    </Carousel> : ''}
</div>
        </>

    )
}
)

export default ServicesDetail