import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '..'
import { API_URL, SERVICE_CATEGORY_ROUTE } from '../utils/consts'
import header from '../assets/header.png'
import axios from 'axios'

const Services = observer(() => {
    const { content } = useContext(Context)
    const navigate = useNavigate()
    useEffect(() => {
        content.setCurrentTab(2)
    })

    const [text, setText] = useState('')
    useEffect(() => {
        axios.get(API_URL + '/api/tekst-v-kategorii-uslugi').then(data => {
            setText(data.data.data.attributes.text)
            //console.log(data.data.data.attributes.text        )
        })
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <div className='home d-flex flex-column justify-content-around' style={{ background: `url(${API_URL + content.mainBanner})`, height: '50vh', position: 'relative' }}>
                <div className='container d-flex flex-column justify-content-center align-items-center' style={{ height: '50vh', zIndex: 10 }}>
                    <div className='container d-flex flex-column justify-content-center align-items-center' style={{ borderRadius: '30px', padding: '40px 40px', width: '70%', background: 'rgb(255 255 255 / 33%)' }}>
                        <div className='container d-flex flex-column justify-content-center align-items-center' style={{ borderRadius: '10px', fontSize: '4rem', width: '100%', background: '#6c6c6c' }}>
                            <h1 style={{ marginBottom: 0 }} >НАШИ УСЛУГИ</h1>
                        </div>
                    </div>

                </div>
                <div className='img' style={{ background: `url(${header})` }} ><img style={{ visibility: 'hidden', width: '100%' }} src={header} /></div>
            </div>
            <div className='container pt-5' style={{ color: 'rgb(80, 75, 75)', fontSize: '1.4rem',whiteSpace: 'pre-wrap' }} >

                {text}
            </div>


            <div className='container-fluid p-lg-5'>
                <div className='row mt-5'>
                    {
                        content.kategorii.length === 0 ? '' :

                            content.kategorii.map((el, i) => {

                                if(el.attributes.imageCategory.data !== null)
                                return (
                                    <div onClick={() => { navigate(SERVICE_CATEGORY_ROUTE + el.id) }} className={`cursor-pointer col-md-4 col-12 pb-3 `}>
                                        <div key={el.id} className='home-portfolio-card d-flex flex-column justify-content-end align-items-center  p-0'
                                           style={{ background: `url(${API_URL + el.attributes.imageCategory.data.attributes.url})` }}>



                                            <div className='home-portfolio-text d-flex flex-column justify-content-end align-items-start w-100 p-3' style={{ color: '#504b4b', fontSize: '1.5em' }}>

                                               
                                                <div style={{textShadow: '1px 2px 2px rgb(114 114 114)'}}>{el.attributes.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                    }
                </div>
            </div>
        </>

    )
}
)

export default Services