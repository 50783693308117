import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "..";
import logo from '../assets/logo.png'
import { API_URL, HOME_ROUTE, VACANCY_ROUTE } from "../utils/consts";
import dost3 from '../assets/dost3.png'
import qr from '../assets/qr.png'
import vk from '../assets/vk.png'
import whatsapp from '../assets/whatsapp.png'
import inst from '../assets/inst.png'
import header from '../assets/header.png'
import ContactForm from "./ContactForm";

const Footer = observer(() => {
    const navigate = useNavigate()
    const { content } = useContext(Context)

    let emails = []
    if (typeof content.kontakty.email !== 'undefined')
        emails = content.kontakty.email.split(' ')


    return (
        <>
            <div className="footer" style={{ background: `#504b4b url(${dost3}) no-repeat` }}>
                <div style={{ background: `url(${header}) no-repeat` }}>
                    <div className="container pt-5">
                        <div className="row text-center">

                        </div>
                        <div className="row d-none d-lg-flex">

                            <div className="col-lg-3">
                                <a onClick={() => navigate(HOME_ROUTE)} className="cursor-pointer"><img src={logo} width="60%" /></a>
                            </div>
                            <div className="col-lg-6">
                                <h1>КОНТАКТЫ</h1>
                                <h5 style={{ whiteSpace: 'pre-wrap', }}>{content.kontakty.address}</h5>
                                <br />
                                <h5>ТЕЛЕФОН: <a href={`tel:${content.kontakty.phone}`}>{content.kontakty.phone}</a></h5>
                                <div className='d-flex flex-row'>
                                    <h5 className=''>ЭЛ. ПОЧТА: </h5>
                                    <div>
                                        {emails.map(el => {
                                            return (
                                                <><h5> <a style={{ textDecoration: 'none', color: '#6ccf5b' }} href={`mailto:${el}`}>{el}</a></h5></>
                                            )
                                        })}

                                    </div>
                                    <br />

                                </div>
                                <br />
                                <h4><a target="_blank" href={`${API_URL + content.referens}`} style={{ color: 'white' }}>Референс</a></h4>
                                <h4 className="cursor-pointer"><a onClick={() => { navigate(VACANCY_ROUTE) }} style={{ color: 'white' }}>Вакансии</a></h4>
                                <br />
                                <div className="row">
                                    <div className="col-lg-4 d-flex d-md-block  justify-content-center mb-4">
                                        <ContactForm />
                                        <div className="d-flex flex-row justify-content-between align-items-center phone-section" style={{ paddingTop: '10px' }}>

                                            <a target="_blank" href={content.kontakty.vk} ><img src={vk} width="40px" /></a>
                                            <a target="_blank" href={content.kontakty.whatsapp} ><img src={whatsapp} width="40px" /></a>
                                            <a target="_blank" href={content.kontakty.inst} ><img src={inst} width="40px" /></a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 d-flex  d-md-block justify-content-center">
                                        <img src={qr} width="120px" />


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex d-lg-none">

                            <div className="col-12">
                                <a onClick={() => navigate(HOME_ROUTE)} className="cursor-pointer"><img src={logo} width="60%" /></a>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6">
                                        <h1>КОНТАКТЫ</h1>
                                        <h5 style={{ whiteSpace: 'pre-wrap', }}>{content.kontakty.address}</h5>
                                    </div>
                                    <div className="col-6">
                                        <img src={qr} width="100%" />
                                    </div>
                                </div>
                                <br />
                               
                                <h5>ТЕЛЕФОН: <a href={`tel:${content.kontakty.phone}`}>{content.kontakty.phone}</a></h5>
                                <div className='d-flex flex-row'>
                                    <h5 className=''>ЭЛ. ПОЧТА: </h5>
                                    <div>
                                        {emails.map(el => {
                                            return (
                                                <><h5> <a style={{ textDecoration: 'none', color: '#6ccf5b' }} href={`mailto:${el}`}>{el}</a></h5></>
                                            )
                                        })}

                                    </div>
                                    <br />

                                </div>

                            </div>


                            <br />
                            <h4><a target="_blank" href={`${API_URL + content.referens}`} style={{ color: 'white' }}>Референс</a></h4>
                            <h4 className="cursor-pointer"><a onClick={() => { navigate(VACANCY_ROUTE) }} style={{ color: 'white' }}>Вакансии</a></h4>
                            <br />
                            <div className="row">
                                <div className="col-lg-4 d-flex flex-column align-items-center justify-content-center mb-4">
                                    <ContactForm />
                                    <div className="d-flex flex-row justify-content-between align-items-center phone-section" style={{ paddingTop: '10px' }}>

                                        <a target="_blank" href={content.kontakty.vk} ><img src={vk} width="40px" /></a>
                                        <a target="_blank" href={content.kontakty.whatsapp} ><img src={whatsapp} width="40px" /></a>
                                        <a target="_blank" href={content.kontakty.inst} ><img src={inst} width="40px" /></a>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ width: '100%', background: '#6ccf5b', padding: '15px' }}>
                <div className="container">
                    © {(new Date()).getFullYear()} ООО ГИЗМА
                    <br />
                    Любая информация, представленная на данном сайте, носит исключительно информационный характер, не явлется публичной офертой, определяемой положениями статьи 437 ГК РФ
                </div>
            </div>
        </>
    )
})

export default Footer