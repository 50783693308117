import { makeAutoObservable } from "mobx"

export default class ContentStore {
    constructor() {
        this._currentTab  = 0
        this._kontakty  = {}
        this._kategorii  = []
        this._portfolio  = []
        this._logotypes  = []
        this._dostizheniya  = []
        this._about  = {}
        this._referens = ''
        this._mainBanner = ''
        this._headerText = ''
        makeAutoObservable(this)
    }



    setReferens(d) {
        this._referens = d
    }
    get referens() {
        return this._referens
    }


    setLogotypes(d) {
        this._logotypes = d
    }
    get logotypes() {
        return this._logotypes
    }


    setPortfolio(d) {
        this._portfolio = d
    }
    get portfolio() {
        return this._portfolio
    }

    setAbout(d) {
        this._about = d
    }
    get about() {
        return this._about
    }


    setCurrentTab(d) {
        this._currentTab = d
    }
    get currentTab() {
        return this._currentTab
    }


    setDostizheniya(d) {
        this._dostizheniya = d
    }
    get dostizheniya() {
        return this._dostizheniya
    }


    setKontakty(d) {
        this._kontakty = d
    }
    get kontakty() {
        return this._kontakty
    }

    setMainBanner(d) {
        this._mainBanner = d
    }
    get mainBanner() {
        return this._mainBanner
    }


    setHeaderText(d) {
        this._headerText = d
    }
    get headerText() {
        return this._headerText
    }

    setKategorii(d) {
        this._kategorii = d
    }
    get kategorii() {
        return this._kategorii
    }
}